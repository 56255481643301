.box-shadow{
    background:whitesmoke;
    padding: 40px;
   /* box-shadow: 4px 3px 8px 1px black;*/
    -webkit-box-shadow: 4px 3px 8px 1px black;
    justify-self: start;
    border-radius: 13px;
    box-shadow: 10 8px 16px 0 rgba(0,0,0,0.5);
  }
  .color-bar{
    color: white;
  }
  .color-bar1{
    color:black;
  }
  .contact-bar{
      margin-top: 110px;
  }
  .about-bar{
    margin-top: 210px;
  }
  .about1-bar{
    margin-top: 50px;
  }
  .btn-color{
    background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    font-family: 'Oxygen', sans-serif;
	text-transform: uppercase;
	color:black;
	background-color: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
	background-image: none;
	padding: 14px 34px;
	letter-spacing: 1px;
	margin: 0;
	font-size: 15px;
	font-weight: 500;
	border-radius: 25px;
	
	border: 0;
  }